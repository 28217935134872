<template>
  <b-card no-body class="">
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Payment Status</b-card-title>
      <h3 style="margin-right: 90px;">Paid</h3>
      <h4>Overall</h4>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body class="h-auto">
      <b-row>
        <!-- <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ trackerData.totalTicket }}
          </h1>
          <b-card-text>Payments</b-card-text>
        </b-col> -->

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center ml-5"
        >
         
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="370"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
            c
          </b-card-text>
          <!-- <span class="font-large-1 font-weight-bold">{{ trackerData.newTicket }}</span> -->
          <span class="font-large-1 font-weight-bold">30</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Refund Pending
          </b-card-text>
          <!-- <span class="font-large-1 font-weight-bold">{{ trackerData.openTicket }}</span> -->
          <span class="font-large-1 font-weight-bold">20</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      trackerData: {},
      supportTrackerRadialBar: {
        series: ['83'],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
            label : ['Paid']
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        labels: ['Completed Tickets'],
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/support-tracker').then(res => { this.trackerData = res.data })
  },
}
</script>
