<template>
    <div>
      <Main />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import Main from "@/components/booking/admin/dashboard/FMNDashboardMain.vue";
  import util from "@/util.js";
  
  export default {
    components: {
      Main,
    },
    mixins: [util],
    data() {
      return {
        data: {},
      };
    },
    async mounted() {},
    methods: {
      ...mapActions({
        getDashboardData: "appData/getDashboardData",
        getDashboardLineChart: "appData/getDashboardLineChart",
      }),
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getLoggedInUser",
      }),
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  