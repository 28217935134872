<template>
  <div>
  <b-row>
  <b-col md="12">
      <card-statistics-group/>
  </b-col>
</b-row>
<b-row>
 
  <b-col md="6">
    <BookingWrtStatuses/>
  </b-col>

  <b-col md="6">
    <b-row>
      <b-col md="4" sm="4" >
        <statistic-card-vertical-vue
          icon="EyeIcon"
          statistic="369"
          statistic-title="Pending"
          color="info"
        />
      </b-col>
      <b-col md="4" sm="4" >
        <statistic-card-vertical-vue
          color="warning"
          icon="MessageSquareIcon"
          statistic="12k"
          statistic-title="Confirmed"
        />
      </b-col>
      <b-col md="4" sm="4">
        <statistic-card-vertical-vue
          color="danger"
          icon="ShoppingBagIcon"
          statistic="97.8k"
          statistic-title="Cancelled"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <BookingsthismonthVue/>
      </b-col>
    </b-row>
  </b-col>
</b-row>
<b-row>
      <b-col
        lg="3"
        sm="6"
      >
      <Total-Payment-Status-vue
          icon="CpuIcon"
          statistic="860"
          statistic-title="Paid"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
      <Total-Payment-Status-vue
          icon="ServerIcon"
          color="success"
          statistic="120"
          statistic-title="Not Paid"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
      <Total-Payment-Status-vue
          icon="ActivityIcon"
          color="danger"
          statistic="12"
          statistic-title="Refunded"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <Total-Payment-Status-vue
          icon="AlertOctagonIcon"
          color="warning"

          statistic="6"
          statistic-title="IRefund Pending"
        />
      </b-col>
    </b-row>

<b-row>


  <!-- <b-col md="6">
 <b-card class="bg-image shadow border-0">
     <card-analytic-customers-vue/>
     </b-card>
  </b-col> -->
  
  <!-- <b-col md="6">
 <b-card class="bg-image shadow border-0">
     <card-analytic-support-tracker-vue/>
     </b-card>
  </b-col>   -->

</b-row>


  </div>
</template>
<script>
import CardStatisticsGroup from "./BookingsGroupData.vue"
import CardAnalyticAvgSessionsVue from "./CardAnalyticAvgSessions.vue"
import CardAnalyticEarningsChart from './CardAnalyticEarningsChart.vue'
import BookingWrtStatuses from "./BookingWrtStatuses.vue"
import CardAnalyticSupportTrackerVue from "./CardAnalyticSupportTracker.vue"
import CardAnalyticEarningsChartVue from "./CardAnalyticEarningsChart.vue"
import StatisticCardVerticalVue from "@/@core/components/statistics-cards/StatisticCardVertical.vue"
// import CardAnalyicsRevenueVue from "./CardAnalyicsRevenue.vue"
import BookingsthismonthVue from "./Bookingsthismonth.vue"
import TotalPaymentStatusVue from "./TotalPaymentStatus.vue"

  export default {
    components: {
      CardAnalyticAvgSessionsVue,
      BookingWrtStatuses,
      CardAnalyticSupportTrackerVue,
      CardAnalyticEarningsChart,
      CardAnalyticEarningsChartVue,
      CardStatisticsGroup,
      StatisticCardVerticalVue,
      // CardAnalyicsRevenueVue,
      BookingsthismonthVue,
      TotalPaymentStatusVue
    },
  }
</script>

<style>

    .bg-image {
    background-image: url('~@/assets/images/newDashboard/Background-1.png');
    background-size: cover;
    background-position: center;
    }
    .shadow{
    box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    }
</style>